import React from 'react';
import bg from "../../assets/bg/standGuy.jpeg";
// import { ReactComponent as ProLabel } from "../../assets/icons/proIcon.svg";
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../buttons/PrimaryButton';

type Props = {};

const FalakPack: React.FC<Props> = (props: Props) => {

    const { t } = useTranslation();

    return (
        <div
            className='relative shadow-md md:shadow-none rounded-xl md:bg-lightGray w-full h-[180px] md:h-fit overflow-hidden '
        >
            <img
                className='object-cover w-full h-full md:hidden brightness-[65%] md:brightness-100 opacity-90 '
                src={bg}
                alt="falak background"
            />
            <div className="absolute md:static h-full w-full top-0 start-0 flex flex-col gap-y-2 items-start text-white md:text-primaryBlack p-4">
                <div className=" h-full flex flex-col gap-y-2 md:gap-y-1 ">
                    <span className="flex items-center  gap-x-2">
                        <span className=' text-white  md:text-primaryOrange text-xs'>
                            {t("soon")+" .."}
                        </span>
                    </span>
                    <p className=' text-lg md:text-sm md:text-primaryBlack'>باقة فلك الأساسية</p>
                    <a
                        href='/#'
                        className=' md:text-sm  text-primaryOrange md:underline hidden md:block'>
                        اكتشف المزايا الرائعة
                    </a>
                </div>
                <div className="flex md:hidden w-full  ">
                    <PrimaryButton
                        authPopUp={false}
                        roundeOrStyling=' rounded-full w-full opacity-80 '
                        title='soon'
                        isOpenSideBar={true}
                        loading={false}
                        handleClick={ () => console.log('')}
                    />
                </div>
            </div>
        </div>
    );
};

export default FalakPack;