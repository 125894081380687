import { ReactComponent as homeIcon } from "../../assets/icons/sideBar/homeIcon.svg";
import { ReactComponent as netWorkSearchIcon } from "../../assets/icons/sideBar/networkSearchIcon.svg";

export const mobileNavElements =
{
    navElements: [
        {
            id: 1,
            title: "home",
            path: "/",
            icon: homeIcon,
        },
        {
            id: 2,
            title: "discoverWithFalak",
            path: "/discover",
            icon: netWorkSearchIcon,
        },

    ],
};
