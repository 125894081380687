import React, { useEffect, useState } from 'react';
import { ReactComponent as MicIcon } from '../../assets/buttons/microphone.svg';
import { ReactComponent as SendIcon } from '../../assets/buttons/sendIcon.svg';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

const SearchComponent = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const { transcript, listening, browserSupportsSpeechRecognition, resetTranscript } = useSpeechRecognition();

    const [searchQuery, setSearchQuery] = useState('');
    const [isRecordTrue, setIsRecordTrue] = useState(false);

    const onQueryChange = (query) => {
        setSearchQuery(query);
    };

    const onQuerySubmit = () => {
        setIsRecordTrue(false);
        if (location.pathname.includes("admin/discover")) {
            searchQuery && navigate("/admin/discover?searchQuery=" + searchQuery);
        }
        else {
            searchQuery && navigate("/search?searchQuery=" + searchQuery);
        }
        searchQuery && resetTranscript();
        searchQuery && setSearchQuery("");
    };

    const handleVoiceSearchClick = () => {

        if (!browserSupportsSpeechRecognition) {
            setIsRecordTrue(false);
            toast.error("Speech recognition is not supported in this browser.");
            return;
        }

        SpeechRecognition.startListening({
            continuous: false,
            language: 'ar-SA'
        });
        setIsRecordTrue(true);
    };

    useEffect(() => {
        if (isRecordTrue) {
            onQueryChange(transcript);
            if (!listening) {
                SpeechRecognition.stopListening();
                onQuerySubmit();
            }
        }
        // eslint-disable-next-line
    }, [transcript, listening]);

    useEffect(() => {
        setSearchQuery("");
        resetTranscript();
        // eslint-disable-next-line
    }, [navigate]);

    return (
        <>
            <section className={`${props.styling} w-full flex flex-col items-center justify-center p-4`}>
                <div className="w-full md:w-fit flex items-center gap-x-2 md:gap-x-4">
                    <div className="block">
                        <button
                            disabled={isRecordTrue}
                            className={` ${isRecordTrue ? " animate-pulse " : ""} w-[52px] md:w-[64px] h-[52px] md:h-[64px] rounded-full flex flex-col items-center justify-center bg-gradient-to-tr from-primaryOrange to-orange-500 shadow-lg hover:brightness-110 hover:shadow-sm duration-150 `}
                            onClick={(searchQuery && !isRecordTrue) ? onQuerySubmit : handleVoiceSearchClick}
                        >
                            {(searchQuery && !isRecordTrue)
                                ? <SendIcon className='w-7 h-7 md:scale-110 -translate-x-[2px] animate-slowfade' />
                                : <MicIcon className={`${isRecordTrue ? "animate-pulse" : ""} animate-slowfade md:scale-110`} />
                            }
                        </button>
                    </div>
                    <div className="relative w-full md:w-fit h-fit flex items-center">
                        <input
                            name=""
                            disabled={listening}
                            value={searchQuery}
                            className={` border-2 border-primaryOrange/55 duration-100 focus:border-orange-400 focus:shadow-md focus:shadow-primaryOrange/25 disabled:brightness-90 disabled:opacity-90 px-4 md:px-6 ps-8 md:ps-9 py-4 md:py-5 w-full md:min-w-[550px] md:max-w-[650px] text-neutral-600 font-normal text-sm md:text-base placeholder:text-neutral-400 rounded-full bg-white shadow-lg md:shadow-md hover:shadow-xl outline-none focus:border-primaryOrangeduration-200`}
                            placeholder={t("askHere")}
                            onChange={(e) => onQueryChange(e.target.value)}
                            onKeyDown={(e) => { if (e.key === 'Enter') { onQuerySubmit(); } }}
                            onFocus={() => setIsRecordTrue(false)}
                        />
                    </div>
                </div>
            </section>

        </>
    );
};

export default SearchComponent;
