import React from "react";

const SourceSkeleton = () => (
    <div className="flex">
        <div className="w-full skeleton-item h-28 md:h-24 mx-3 p-4 md:p-2 m-2 "></div>
        <div className="w-full skeleton-item h-28 md:h-24 mx-3 p-4 md:p-2 m-2 hidden md:block"></div>
    </div>
);

export default SourceSkeleton;
