import { RouterProvider } from "react-router-dom";
import RtlHoc from "./utils/RtlHoc";
import { Router } from "./routes/Router";
import CookieConsent from "react-cookie-consent";
import { useEffect, useState } from "react";

const Main = () => {
  return (
    <RouterProvider router={Router} />
  );
};

const App = () => {

  const [userLocation, setUserLocation] = useState<any>(null);
  const [showCookieConsent, setShowCookieConsent] = useState<boolean>(false);

  useEffect(() => {
    const getUserLocationByTimeZone = () => {
      const timeZoneOffset = new Date().getTimezoneOffset();
      const isNorthAmerica = timeZoneOffset >= 240 && timeZoneOffset <= 300;
      const isEurope = timeZoneOffset <= 60 || timeZoneOffset >= 120;

      if (isNorthAmerica) {
        return 'US';
      } else if (isEurope) {
        return 'Europe';
      } else {
        return 'Unknown';
      }
    };

    setUserLocation(getUserLocationByTimeZone());
  }, []);

  useEffect(() => {
    setShowCookieConsent(userLocation === 'US' || userLocation === 'Europe');
  }, [userLocation]);


  return (
    <>
      <div className="App">
        <RtlHoc
          supportedLanguages={["ar"]}
          app={<Main />}
        />
        {showCookieConsent && (
          <CookieConsent
            location="bottom"
            buttonText="أوافق"
            declineButtonText="لا أوافق"
            cookieName="myAwesomeCookieName"
            style={{
              background: "#2B373B",
              width: "100%",
              padding: "20px",
              textAlign: "center",
              position: "fixed",
              bottom: "0",
              left: "0",
              zIndex: "9999",
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "center",
              alignItems: "center"
            }}
            buttonStyle={{
              background: "#DC5F38",
              color: "#fff",
              fontSize: "16px",
              padding: "8px 22px",
              borderRadius: "8px",
              margin: "0 5px",
              fontWeight: "normal"
            }}
            declineButtonStyle={{
              background: "#f8f8ff",
              color: "#000",
              fontSize: "14px",
              padding: "5px 10px",
              margin: "0 5px"
            }}
            expires={150}
          >
            نحن نستخدم ملف تعريف لتحسين خدماتنا وتجربة المستخدم. لمزيد من المعلومات، يرجى الاطلاع على <a href="https://askfalak.ai/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: "#ffffff" }}>سياسة الخصوصية</a> الخاصة بنا
          </CookieConsent>


        )}
      </div>

    </>
  );
};

export default App;