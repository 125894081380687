import { useEffect } from "react";
import { Link } from "react-router-dom";

interface SourceCardProps {
    title: string;
    url: string;
}

const SourceCard = ({ title, url }: SourceCardProps) => {

    // const [backgroundImage, setBackgroundImage] = useState<string | null>(null);

    useEffect(() => {
        const fetchWebsiteContent = async () => {
            try {
                const response = await fetch(url);
                const html = await response.text();
                const tempElement = document.createElement("div");
                tempElement.innerHTML = html;

                // const logoElement: any = tempElement.querySelector('img[src*="logo"]');

                // if (logoElement) {
                //     setBackgroundImage(`url('${logoElement.src}')`);
                // }
            } catch (error) {
                console.error("Error fetching website content:", error);
            }
        };

        fetchWebsiteContent();
    }, [url]);

    return (
        <Link className="block" to={url} target="_blank">
            <div className=" h-28 md:h-24 mx-3 shadow-sm flex flex-col justify-between items-end md:shadow-none hover:bg-lightOrange bg-lightGray md:bg-white rounded-lg p-4 md:p-2 m-2 overflow-auto scrollbar-thin scrollbar-thumb-gray-500">
                <p className="text-sm text-end w-full ">
                    {title}
                </p>
                <img
                    className="w-4 h-4"
                    src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${url}`}
                    alt="source icon"
                />
            </div>
        </Link>
    );
}

export default SourceCard;