import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {

    const { t } = useTranslation();

    return (
        <div className="flex flex-col w-full max-w-[1920px] mx-auto mt-[67px] h-[75vh] md:mt-0 md:h-full p-4">
            <div className="relative h-full w-full flex flex-col items-center justify-center gap-y-3">

                <div className="h-full w-full flex flex-col items-center justify-center text-center text-[180px] md:text-[400px] text-neutral-200/70 ">
                    <span className="font-bold tracking-wider animate-slowfade">404</span>
                </div>

                <div className="absolute flex flex-col items-center justify-center top-0 start-0 w-full h-full">
                    <div className="flex flex-col w-full text-center space-y-3 md:space-y-8 font-bold">
                        <div className="flex flex-col w-full space-y-2 md:space-y-5 ">
                            <h1 className="text-xl md:text-4xl max-w-lg text-center mx-auto animate-slowfade2">{t("notFound.title")}</h1>
                            <p className="w-full text-neutral-500 text-xs md:text-base font-normal md:tracking-wide animate-slowfade2">{t("notFound.message")}</p>
                        </div>

                    </div>
                </div>

                <Link
                    to="/"
                    className="absolute bottom-[27%] md:bottom-[12%] font-bold text-base md:text-lg w-fit mx-auto py-2 md:py-3 px-12 md:px-20 bg-primaryOrange outline-none rounded-full hover:brightness-125 hover:shadow-lg text-white border-transparent duration-200 animate-slideup"
                >
                    {t("backHome")}
                </Link>

            </div>
        </div>
    )
};

export default NotFound