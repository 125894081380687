import { createContext, useContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  signOut,
  onAuthStateChanged
} from "firebase/auth";

import { auth, db } from '../config/firebase/firebaseConfig';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';
import { doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

  const { t } = useTranslation();

  const [user, setUser] = useState({});
  const [userPhotoUrl, setUserPhotoUrl] = useState("");
  const [isLoadingSignIn, setIsLoadingSignIn] = useState(false);
  const [isLoadingSignInWithPopUp, setIsLoadingSignInWithPopUp] = useState(false);
  const [isLoadingSignUp, setIsLoadingSignUp] = useState(false);
  const [isLoadingResetPassword, setIsLoadingResetPassword] = useState(false);

  const googleSignIn = () => {

    setIsLoadingSignInWithPopUp(true);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((response) => {
        secureLocalStorage.setItem("a2lzc2Vtb3VoR2Ez", response.user.displayName);
        secureLocalStorage.setItem("YXJvYmF6bw==", response.user.email);
        secureLocalStorage.setItem("Zmlnb3VydGVo", response.user.photoURL);
        secureLocalStorage.setItem("cmE5bVRhM3JpZm8=", response.user.uid);
        toast.success(<div className=' text-center text-xs md:text-sm text-primaryBlack '>{t('welcomeUser') + " " + response.user.displayName}</div>, { duration: 3500 })
      })
      .catch((error) =>
        toast.error(
          <div className=' text-center text-xs md:text-sm text-red-500'>{t(error.code)}</div>,
          {
            duration: 3500,
            position: "top-center"
          },
        )
      )
      .finally(() => setIsLoadingSignInWithPopUp(false));
  };

  const googleSignInWithCredentials = (email, password) => {

    setIsLoadingSignIn(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        secureLocalStorage.setItem("a2lzc2Vtb3VoR2Ez", response.user.displayName);
        secureLocalStorage.setItem("YXJvYmF6bw==", response.user.email);
        secureLocalStorage.setItem("Zmlnb3VydGVo", response.user.photoURL);
        secureLocalStorage.setItem("cmE5bVRhM3JpZm8=", response.user.uid);
        toast.success(<div className=' text-center text-xs md:text-sm text-primaryBlack '>{t('welcome') + " " + response.user.displayName}</div>,
          {
            duration: 3500,
            position: "top-center"
          }
        )
      })
      .catch((error) =>
        toast.error(<div className=' text-center text-xs md:text-sm text-red-500'>{t(error.code)}</div>, { duration: 3500 })
      )
      .finally(() => setIsLoadingSignIn(false));
  };

  const registerUser = (email, password, fullName) => {

    setIsLoadingSignUp(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then((response) => {
        updateProfile(response.user, {
          displayName: fullName,
          // photoURL: "https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?w=740&t=st=1712867929~exp=1712868529~hmac=2eea7a9075b4866388ac56d7f25dc4edc9b6114989f869f2b96f0f0de0007d53"
        })
          .then((response) => {
            secureLocalStorage.setItem("a2lzc2Vtb3VoR2Ez", response.user.displayName);
            secureLocalStorage.setItem("YXJvYmF6bw==", response.user.email);
            secureLocalStorage.setItem("Zmlnb3VydGVo", response.user.photoURL);
            secureLocalStorage.setItem("cmE5bVRhM3JpZm8=", response.user.uid);
          })
          .catch((error) =>
            console.log(error)
          )
          .finally(() => setIsLoadingSignUp(false));
      })
      .catch((error) =>
        toast.error(<div className=' text-center text-xs md:text-sm text-red-500'>{t(error.code)}</div>, { duration: 3500 })
      )
      .finally(() => setIsLoadingSignUp(false));
  }

  const resetPassword = (email) => {

    setIsLoadingResetPassword(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        window.location.replace('/password-reset-email');
      })
      .catch((error) =>
        toast.error(<div className=' text-center text-xs md:text-sm text-red-500'>{t(error.code)}</div>, { duration: 3500 })
      )
      .finally(() => setIsLoadingResetPassword(false));
  };

  const logOut = () => {
    signOut(auth);
    setUser(null);
    secureLocalStorage.clear();
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (currentUser) => {

      if (currentUser) {
        currentUser.isAdmin = false;

        // Checking if the user is an admin
        const isAdminRef = doc(db, 'admins', currentUser.uid);
        const isAdminDoc = await getDoc(isAdminRef);
        const isAdmin = isAdminDoc.exists() && isAdminDoc.data().isActive;
        currentUser.isAdmin = isAdmin;

        setUser(currentUser);
        setUserPhotoUrl(currentUser.photoURL);

        secureLocalStorage.setItem("a2hhZG10ZWg=", isAdmin);
        secureLocalStorage.setItem("a2lzc2Vtb3VoR2Ez", currentUser.displayName);
        secureLocalStorage.setItem("YXJvYmF6bw==", currentUser.email);
        secureLocalStorage.setItem("Zmlnb3VydGVo", currentUser.photoURL);
        secureLocalStorage.setItem("cmE5bVRhM3JpZm8=", currentUser.uid);
      }
      else {
        setUser(null);
        secureLocalStorage.clear();
      }
    });
  }, []);


  return (
    <AuthContext.Provider value={
      {
        googleSignIn,
        googleSignInWithCredentials,
        registerUser,
        resetPassword,
        logOut,
        user,
        userPhotoUrl,
        isLoadingSignIn,
        isLoadingSignInWithPopUp,
        isLoadingSignUp,
        isLoadingResetPassword
      }
    }>
      {children}
    </AuthContext.Provider>
  );
};


export const UserAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('UserAuth error');
  }
  return context;
};
