import { ReactComponent as instagramIcon } from "../assets/social/instagram.svg";
import { ReactComponent as facebookIcon } from "../assets/social/facebook.svg";
import { ReactComponent as youtubeIcon } from "../assets/social/youtube.svg";
import { ReactComponent as xwitterIcon } from "../assets/social/x-twitter.svg";


export const socialMediaLinks = {
    navElements: [
        {
            id: 1,
            title: "instagram",
            link: "https://www.instagram.com/askfalak.ai/?igsh=MWZtcmN3NXVhZ2ZtNQ%3D%3D",
            icon: instagramIcon,
        },
        {
            id: 1,
            title: "facebook",
            link: "https://www.facebook.com/profile.php?id=61555700982813&mibextid=ZbWKwL",
            icon: facebookIcon,
        },
        {
            id: 1,
            title: "youtube",
            link: "https://www.youtube.com/watch?v=gpzjQdWRhqo&ab_channel=%D9%81%D9%84%D9%83",
            icon: youtubeIcon,
        },
        {
            id: 1,
            title: "twitter",
            link: "https://twitter.com/askfalak_ai?t=HJFXeChcg2OJSKTULvEJ8A&s=08",
            icon: xwitterIcon,
        },
    ]
};