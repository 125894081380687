import React from "react";

import { ReactComponent as SettingsIcon } from "../../assets/buttons/settingsIcon.svg";
import { ReactComponent as UserAvatar } from "../../assets/avatar/UserAvatar.svg";
import { UserState } from "../../data/types/UserState";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  userInfos: UserState,
  isOpenSideBar?: boolean,
}

const UserInfosSection: React.FC<Props> = (props: Props) => {

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <section
      className={` cursor-pointer flex items-center hover:bg-lightOrange rounded-lg 
      ${props.isOpenSideBar ? "  justify-between p-2 " : " justify-around flex w-full gap-x-3 group duration-200 p-2 rounded-2xl "}
       ${location.pathname === "/settings" ? "bg-lightOrange" : ""} `}
      onClick={() => navigate("/settings")}
    >
      {/* profile infos */}
      <div className={` flex items-center gap-x-2  ${props.isOpenSideBar ? "scale-100" : "hidden scale-0 "} `}>

        {/* avatar */}
        <span
          className={` block  ${props.isOpenSideBar ? " " : " "} `}
        >
          <UserAvatar className="w-10" />
        </span>

        {/* infos */}
        <div className={` flex flex-col gap-y-1  ${props.isOpenSideBar ? " scale-100 duration-300 " : " scale-0 duration-300  "} `}>
          <h5 className=" text-sm text-primaryBlack text-right ">{props.userInfos.displayName}</h5>
          <p
            style={{ direction: 'ltr' }}
            className=" text-xs text-neutral-500 text-right font-light "
          >
            {props.userInfos.email && props.userInfos.email.slice(0, 20) + "..."}
          </p>
        </div>

      </div>

      {/* settings */}
      <SettingsIcon
        className={` ${location.pathname === "/settings" ? " text-primaryOrange " : " text-primaryBlack "} w-6 h-6 `}
      />

    </section>
  );
};

export default UserInfosSection;
