import React from 'react'
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../includes/LoadingSpinner';

type Props = {
    title: string;
    roundeOrStyling: string;
    disabled?: boolean,
    loading: boolean;
    isOpenSideBar?: boolean;
    handleClick: () => void;
    icon?: React.ReactNode;
};

const SecondaryButton: React.FC<Props> = ({ title, loading, isOpenSideBar, handleClick, roundeOrStyling, disabled, icon }) => {

    const { t } = useTranslation();

    return (
        <button
            type='button'
            disabled={loading || disabled}
            className={` group disabled:opacity-60 h-[48px] bg-lightGray py-[6px] md:py-[10px] w-full flex flex-col items-center justify-center ${roundeOrStyling} text-sm md:text-base text-primaryBlack  hover:brightness-110 shadow-md duration-150`}
            onClick={handleClick}
        >
            {loading
                ? (< LoadingSpinner />)
                :
                <div className="flex items-center gap-x-3 px-4">
                    <span className={` ${isOpenSideBar ? " group-hover:scale-105 duration-150 " : " md:group-hover:-scale-105 duration-150 "} `}>
                        {icon && icon}
                    </span>
                    <span className={` ${isOpenSideBar ? " scale-100  duration-300  " : " hidden scale-0 w-0 duration-75 "} text-sm md:text-base `}>

                        {t(title)}
                    </span>
                </div>
            }

        </button>
    );
};

export default SecondaryButton;