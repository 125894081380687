import React, { useState, useEffect } from 'react';
import Sidebar from '../components/global/Sidebar';
import Footer from '../components/global/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import MobileBottomNav from '../components/global/MobileBottomNav';
import SearchComponent from '../components/search/SearchComponent';
import LoginToFalakModal from '../components/modals/LoginToFalakModal';
import { ReactComponent as Falak } from "../assets/identity/falak.svg";
import { UserAuth } from '../context/AuthContext';
import FirestoreDataProvider from '../context/firestoreDataContext';
import useScrollTop from '../utils/scrollTop';

const Layout: React.FC = () => {

    const { user } = UserAuth();
    const [isContextProvided, setIsContextProvided] = useState<boolean>(false); // State to track if context is loaded

    useEffect(() => {
        if (user !== null) {
            setIsContextProvided(true);
        } else if (user === null) {
            setIsContextProvided(true);
        }
    }, [user]);

    const desiredPathnames = ['/home', '/search', '/'];
    const location = useLocation();

    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

    const updateIsMobile = () => {
        setIsMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateIsMobile);
        return () => window.removeEventListener('resize', updateIsMobile);
    }, []);

    const [showPopup, setShowPopup] = useState<boolean>(false);

    useEffect(() => {
        if (!isContextProvided || user === null) { // Check if user context is not loaded or user is null
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 8000);
        } else {
            setShowPopup(false);
        }
    }, [user, isContextProvided]);

    const handlePopupClose = () => {
        setShowPopup(false);
    };

    useScrollTop();

    return (
        <>
            <FirestoreDataProvider>
                {
                    !isContextProvided ?
                        <div className="fixed h-screen w-screen flex flex-col items-center justify-center bg-lightGray">
                            <Falak className="w-16 md:w-16 h-20 md:h-20 animate-spin rounded-full" />
                        </div>
                        :
                        <main className='relative'>
                            <div
                                className={isMobile ? 'layout md:hidden md:h-screen flex flex-col justify-between max-w-[1920px] mx-auto bg-lightGray'
                                    : "relative hidden md:h-screen h-screen mx-auto md:flex items-start bg-lightGray "}
                            >
                                {!isMobile &&
                                    <Sidebar />
                                }

                                <div className={isMobile
                                    ? 'block overflow-y-auto h-[calc(100%-76px)]'
                                    : "relative flex flex-col w-full h-full bg-transparent animate-slowfade2 bg-lightGray md:p-6 md:px-10 md:mb-0  overflow-y-auto "}>
                                    <div className={isMobile ? " h-full" : "w-full max-w-[1920px] mx-auto h-fit flex flex-col"}>
                                        <Outlet />
                                    </div>

                                    {isMobile &&
                                        <>
                                            {desiredPathnames.includes(location.pathname) && (
                                                <div className="w-full block">
                                                    <SearchComponent styling='fixed bottom-[80px] md:bottom-6 mx-auto' />
                                                </div>
                                            )}
                                        </>
                                    }

                                    {!isMobile &&
                                        <Footer />
                                    }
                                </div>
                                {isMobile && <MobileBottomNav />}
                            </div>

                            {!isMobile && showPopup &&
                                <LoginToFalakModal close={handlePopupClose} />
                            }
                        </main>
                }


            </FirestoreDataProvider>
        </>
    );
};

export default Layout;
