import { doc, writeBatch } from 'firebase/firestore';
import React, { useState } from 'react';
import { db } from '../../config/firebase/firebaseConfig';
import LoadingSpinner from '../../components/includes/LoadingSpinner';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { UserAuth } from '../../context/AuthContext';
import { ReactComponent as SharesIcon } from '../../assets/buttons/shareAnswerIcon.svg';
import { ReactComponent as TimeIcon } from '../../assets/icons/timeIcon.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/eyeIcon.svg';

type Props = {
    index: number
    articleInfos: any;
    onDeleted: (articleId: string) => void;
};

const NewsArticleCard: React.FC<Props> = ({ index, articleInfos, onDeleted }: Props) => {

    const { t } = useTranslation();
    const { user } = UserAuth();

    const [loading, setLoading] = useState<boolean>(false);


    const handleDeleteArticle = async () => {
        setLoading(true);

        const batch = writeBatch(db);

        try {
            // Delete original article
            const articleDocRef = doc(db, `/discover-articles/${articleInfos.articleId}`);
            batch.delete(articleDocRef);

            // Delete meta article
            const metaArticleDocRef = doc(db, `/discover-meta-articles/${articleInfos.id}`);
            batch.delete(metaArticleDocRef);

            // Commit the batch
            await batch.commit();

            toast.success(
                <div className='text-center text-xs md:text-sm text-primaryBlack'>{t('discoverArticleDeleted')}</div>,
                { duration: 3500, position: "top-center" }
            );

            setLoading(false);
            onDeleted(articleInfos.id);
        } catch (error: any) {
            toast.error(<div className='text-center text-xs md:text-sm text-red-500'>{t(error.code)}</div>, { duration: 3500 });
            setLoading(false);
        }
    };

    const calculateTimeDifference = (createdAt: Date): string => {
        const now = new Date();
        const difference = now.getTime() - createdAt.getTime();
        const minutes = Math.floor(difference / (1000 * 60));
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const months = Math.floor(days / 30);
        const years = Math.floor(days / 365);

        if (difference < 60000) {
            return 'منذ لحظات';
        } else if (minutes < 60) {
            return `منذ ${minutes} ${_getArabicUnit(minutes, 'دقيقة', 'دقائق')}`;
        } else if (hours < 24) {
            return `منذ ${hours} ${_getArabicUnit(hours, 'ساعة', 'ساعات')}`;
        } else if (days < 30) {
            return `منذ ${days} ${_getArabicUnit(days, 'يوم', 'أيام')}`;
        } else if (months < 12) {
            return `منذ ${months} ${_getArabicUnit(months, 'شهر', 'أشهر')}`;
        } else {
            return `منذ ${years} ${_getArabicUnit(years, 'سنة', 'سنوات')}`;
        }
    };

    const _getArabicUnit = (value: number, singular: string, plural: string): string => {
        if (value > 10) {
            return singular;
        } else {
            return plural;
        }
    };

    const timeDifference = calculateTimeDifference(new Date(articleInfos && articleInfos.createdAt && articleInfos.createdAt));

    return (
        <div className="flex items-center bg-transparent group md:gap-x-2 duration-150 animate-slowfade">
            <a
                className=""
                href={`https://discover.askfalak.ai/ar/${articleInfos && articleInfos.articleId && articleInfos.articleId}`}
            >

                <div
                    className='md:hidden w-full flex flex-col gap-y-4 cursor-pointer group mingap-2 p-3 bg-lightGray shadow-sm rounded-xl hover:brightness-105 duration-100'
                >

                    <div className='block col-span-3 md:col-span-2'>
                        <img
                            className='w-full h-[185px] md:group-hover:scale-110 duration-300 rounded-lg '
                            src={articleInfos.thumbnail.url}
                            alt="Falak news article"
                        />
                    </div>

                    <div className=" col-span-9 md:col-span-7 flex flex-col gap-y-2 ">

                        <div className="w-full flex items-center justify-between">
                            <h3 className=' md:group-hover:text-primaryOrange duration-150 text-base font-bold'>
                                {articleInfos?.title}
                            </h3>
                        </div>

                        <p className='text-sm leading-4 md:leading-6 text-neutral-600'>
                            {articleInfos.response.length > 250
                                ? articleInfos.response.slice(0, 250) + " ... "
                                : articleInfos.response}
                        </p>
                    </div>
                    <ul className="flex items-center gap-x-3 md:gap-x-4 text-xs md:text-sm">

                        <div className="flex items-center gap-x-1 md:gap-x-2">
                            <EyeIcon className='w-4' />
                            <p className='text-xs md:text-sm text-neutral-600 '>{articleInfos.views}</p>
                        </div>
                        <div className="flex items-center gap-x-1 md:gap-x-2">
                            <SharesIcon className='w-4' />
                            <p className='text-xs md:text-sm text-neutral-600 '>{articleInfos.shares}</p>
                        </div>
                        <div className="flex items-center gap-x-1 md:gap-x-2">
                            <TimeIcon className='w-4' />
                            <p className='text-xs md:text-sm text-neutral-600 '>{timeDifference}</p>
                        </div>
                    </ul>

                </div>

                {/* LG */}
                <div
                    className='hidden cursor-pointer group min-h-[114px] md:min-h-full md:grid grid-cols-12 md:grid-cols-10 gap-2 md:gap-6 p-3 md:p-6 bg-lightGray md:bg-white shadow-sm rounded-xl md:hover:shadow-lg hover:brightness-105 duration-100'
                >

                    <div className='block col-span-3 h-[220px]'>
                        <img
                            className='h-full w-full md:group-hover:scale-105 duration-300 rounded-lg '
                            src={articleInfos.thumbnail.url}
                            alt="Falak news article"
                        />
                    </div>

                    <div className="h-[220px] col-span-7 flex flex-col justify-between">

                        <div className="block space-y-2">
                            <div className="w-full flex items-center justify-between">
                                <h3 className=' md:group-hover:text-primaryOrange duration-150 text-xl font-bold'>
                                    {articleInfos?.title}
                                </h3>
                            </div>

                            <p className='hidden md:block leading-4 md:leading-6 text-base text-neutral-600'>
                                {articleInfos.response.length > 400
                                    ? articleInfos.response.slice(0, 400) + " ... "
                                    : articleInfos.response}
                            </p>
                        </div>

                        <ul className="flex items-center gap-x-4 text-sm">

                            <div className="flex items-center gap-x-2">
                                <EyeIcon className='w-5' />
                                <p className='text-base text-neutral-600 '>{articleInfos.views}</p>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <SharesIcon className='w-5' />
                                <p className='text-base text-neutral-600 '>{articleInfos.shares}</p>
                            </div>
                            <div className="flex items-center gap-x-2">
                                <TimeIcon className='w-5' />
                                <p className='text-base text-neutral-600 '>{timeDifference}</p>
                            </div>
                        </ul>
                    </div>
                </div>

            </a >
            {user?.isAdmin &&
                <>
                    {loading ? <LoadingSpinner />
                        :
                        <button
                            className=' scale-0 group-hover:scale-100 group-hover:w-fit hidden md:block bg-primaryRed text-white z-40 group-hover:px-2 border-primaryRed border-2 rounded duration-300 hover:text-primaryRed hover:bg-transparent shadow-lg'
                            onClick={handleDeleteArticle}
                        >
                            X
                        </button>
                    }
                </>
            }
        </div>
    );
};

export default NewsArticleCard;
