import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { mobileNavElements } from '../../data/types/mobileNavElements';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SignOutIcon } from "../../assets/buttons/loginIcon.svg";
import { ReactComponent as HistoryIcon } from "../../assets/icons/sideBar/historyIcon.svg";
import MobileBottomNavLink from '../includes/MobileBottomNavLink';
import { UserAuth } from '../../context/AuthContext';
import GoogleAuthModal from '../modals/GoogleAuthModal';


const MobileBottomNav: React.FC = () => {

    const location = useLocation();
    const { t } = useTranslation();

    const { user } = UserAuth();

    const [showAuthModal, setShowAuthModal] = useState(false);

    const handleShowAuthModal = () => {
        setShowAuthModal(true);
    };


    return (
        <nav className="fixed bottom-0 block z-0 w-full bg-lightGray shadow-lg md:hidden">
            <ul className="h-fit grid grid-cols-3">
                {mobileNavElements.navElements.map((navItem) => (
                    <li key={navItem.id}>
                        <MobileBottomNavLink
                            to={navItem.path}
                            icon={navItem.icon}
                            title={t(navItem.title)}
                            isActive={location.pathname === navItem.path}
                        />
                    </li>
                ))}
                <li key={mobileNavElements.navElements.length + 1}>
                    {
                        user ?
                            <MobileBottomNavLink
                                to="/history"
                                icon={HistoryIcon}
                                title={t("recentQuests")}
                                isActive={location.pathname === "/history"}
                            />
                            :
                            <>
                                <button
                                    className={`py-4 mx-3 flex flex-col gap-y-1 items-center justify-center ${location.pathname === "/sign-in" ? 'text-primaryOrange' : 'text-primaryBlack'}`}
                                    onClick={handleShowAuthModal}
                                >
                                    <SignOutIcon className="w-6 h-6" />
                                    <span className="text-xs">{t("signIn")}</span>
                                </button>

                                {showAuthModal &&
                                    <GoogleAuthModal close={() => setShowAuthModal(false)} />
                                }
                            </>
                    }
                </li>
            </ul>
        </nav>
    );
};

export default MobileBottomNav;
