import React from "react";

import NewsCard from "./SourceCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SourceSkeleton from "./SourceSkeleton";

const SliderComponent = (props) => {

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <ArrowButton />,
    prevArrow: <ArrowButton />,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="block w-full slider-container px-2 pb-4">
      {!props.result?.sources ? (
        <SourceSkeleton />
      ) : (
        <Slider className="flex" {...settings}>
          {props.result?.sources?.map((source, i) => (
            <div key={i}>
              <NewsCard title={source.title} url={source.url} />
            </div>
          ))}
        </Slider>
      )}
    </section>
  );
};

function ArrowButton(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent" }}
      onClick={onClick}
    />
  );
}

export default SliderComponent;
