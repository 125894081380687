import React from "react";
import { Link } from "react-router-dom";

interface MobileBottomNavLinkProps {
    to: string;
    icon: React.ComponentType<{ className?: string }>;
    title: string;
    isActive: boolean;
}

const MobileBottomNavLink: React.FC<MobileBottomNavLinkProps> = ({ to, icon: Icon, title, isActive }) => {
    return (
        <Link
            to={to}
            className={`py-4 mx-3 flex flex-col gap-y-1 items-center justify-center ${isActive ? 'text-primaryOrange' : 'text-primaryBlack'}`}
        >
            <Icon className="w-6 h-6" />
            <span className="text-xs">{title}</span>
        </Link>
    );
};

export default MobileBottomNavLink;