import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../includes/LoadingSpinner';
import GoogleAuthModal from '../modals/GoogleAuthModal';
import { RWebShare } from 'react-web-share';

type Props = {
    authPopUp: boolean;
    title: string;
    roundeOrStyling: string;
    disabled?: boolean,
    loading: boolean;
    isOpenSideBar?: boolean;
    handleClick: () => void;
    icon?: React.ReactNode;
};

const PrimaryButton: React.FC<Props> = ({ authPopUp, title, loading, isOpenSideBar, handleClick, roundeOrStyling, disabled, icon }) => {

    const { t } = useTranslation();
    const [showAuthModal, setShowAuthModal] = useState(false);

    const handleShowAuthModal = () => {
        setShowAuthModal(true);
    };

    return (
        <>
            {title === "shareTheWebsite" ?
                <RWebShare
                    data={{
                        text: "شارك الموقع ",
                        url: window.location.href,
                        title: "أول محرك بحث عربي باستخدام الذكاء الاصطناعي",
                    }}
                >
                    <button
                        className={` relative group disabled:opacity-60 h-[48px] bg-primaryOrange py-[6px] md:py-[10px] w-full flex flex-col items-center justify-center ${roundeOrStyling} text-sm md:text-base text-white  hover:brightness-110 shadow-md duration-150`}
                        onClick={ () => console.log('')}
                    >
                        <div className="flex items-center gap-x-3 px-4">
                            <span className={` ${isOpenSideBar ? " gropup-hover:translate-x-1 duration-150 " : " md:group-hover:-translate-x-1 duration-150 "} `}>
                                {icon && icon}
                            </span>
                            <span className={` ${isOpenSideBar ? " scale-100  duration-300  " : " hidden scale-0 w-0 duration-75 "} text-sm md:text-base `}>

                                {t(title)}
                            </span>
                        </div>
                    </button>
                </RWebShare>

                :
                <button
                    disabled={loading || disabled}
                    className={` relative group disabled:opacity-60 h-[48px] bg-primaryOrange py-[6px] md:py-[10px] w-full flex flex-col items-center justify-center ${roundeOrStyling} text-sm md:text-base text-white  hover:brightness-110 shadow-md duration-150`}
                    onClick={authPopUp ? handleShowAuthModal : handleClick}
                >
                    {loading
                        ? (< LoadingSpinner />)
                        :
                        <div className="flex items-center gap-x-3 px-4">
                            <span className={` ${isOpenSideBar ? " gropup-hover:translate-x-1 duration-150 " : " md:group-hover:-translate-x-1 duration-150 "} `}>
                                {icon && icon}
                            </span>
                            <span className={` ${isOpenSideBar ? " scale-100  duration-300  " : " hidden scale-0 w-0 duration-75 "} text-sm md:text-base `}>

                                {t(title)}
                            </span>
                        </div>
                    }
                </button>
            }


            {showAuthModal &&
                <GoogleAuthModal close={() => setShowAuthModal(false)} />
            }
        </>
    )
}

export default PrimaryButton