import React, { useState, useEffect, createContext, useContext } from 'react';
import { db } from '../config/firebase/firebaseConfig';
import { collection, getDocs, limit, orderBy, query, startAfter } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { ARTICLES_PER_PAGE } from '../pages/discover/Discover';

const FirestoreDataContext = createContext();

export const useFirestoreGetArticles = () => useContext(FirestoreDataContext);

const FirestoreDataProvider = ({ children }) => {

    const { t } = useTranslation();

    const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [currentPageArticles, setCurrentPageArticles] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const getArticles = async () => {
            try {
                setIsLoading(true);
                let q;

                // If there are enough articles in the array to cover the next page
                if (articles.length >= currentPage * ARTICLES_PER_PAGE) {
                    const startIndex = (currentPage - 1) * ARTICLES_PER_PAGE;
                    const currentPageArticles = articles.slice(startIndex, startIndex + ARTICLES_PER_PAGE);
                    setCurrentPageArticles(currentPageArticles);
                    setIsLoading(false); // No need to fetch data, use existing data
                    return; // Exit early
                }

                // If it's not the first page, set the startAfter cursor
                if (currentPage > 1 && articles.length > 0) {
                    const lastVisibleArticle = articles[articles.length - 1]; // Get the last article from the previous page
                    q = query(
                        collection(db, `/discover-meta-articles`),
                        orderBy("createdAt", "desc"),
                        startAfter(lastVisibleArticle.createdAt), // Use the createdAt timestamp of the last article as the cursor
                        limit(ARTICLES_PER_PAGE)
                    );
                } else {
                    q = query(
                        collection(db, `/discover-meta-articles`),
                        orderBy("createdAt", "desc"),
                        limit(6)
                    );
                }

                const snapshot = await getDocs(q);
                const tempArr = [];
                snapshot.forEach((doc) => {
                    tempArr.push({ id: doc.id, ...doc.data() });
                });

                if (currentPage === 1) {
                    setArticles(tempArr);
                } else {
                    setArticles([...articles, ...tempArr]);
                }

                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                console.error(error);
                toast.error(<div className='text-center text-xs md:text-sm text-red-500'>{t(error.code)}</div>, { duration: 3500 });
            }
        };

        getArticles();
        // eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * ARTICLES_PER_PAGE;
        const currentPageArticles = articles.slice(startIndex, startIndex + ARTICLES_PER_PAGE);
        setCurrentPageArticles(currentPageArticles);
    }, [currentPage, articles]);


    return (
        <FirestoreDataContext.Provider value={{ currentPageArticles, articles, setArticles, isLoading, currentPage, setCurrentPage }}>
            {children}
        </FirestoreDataContext.Provider>
    );
};

export default FirestoreDataProvider;
