import React from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as LoginIcon } from '../../assets/buttons/loginIcon.svg';
import { ReactComponent as CloseButtonIcon } from '../../assets/buttons/x.svg';
import SecondaryButton from '../buttons/SecondaryButton';
import { ReactComponent as Falak } from "../../assets/identity/falak.svg";
import { ReactComponent as GoogleIcon } from '../../assets/icons/googleIcon.svg';
import { UserAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';


type Props = {
    close: () => void;
};

const LoginToFalakModal: React.FC<Props> = (props: Props) => {

    const { googleSignIn } = UserAuth();

    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleGoogleAuthClick = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log(error);
        }
    };

    return (

        <div
            className=' hidden md:block bottom-6 end-6 w-fit p-8 shadow-lg absolute bg-primaryWhite  rounded-3xl animate-slideup '
        >
            <div className="h-full w-full flex flex-col md:gap-y-8 items-center justify-end gap-y-8">
                <div className="hidden md:block">

                </div>
                <button
                    className=' top-6 start-6 absolute text-primaryBlack/55 hover:text-primaryBlack/100 duration-150 flex items-center gap-x-2 '
                    onClick={props.close}
                >
                    <CloseButtonIcon />
                </button>

                <div className="flex flex-col h-full items-center justify-center  ">
                    <div className="flex flex-col items-center justify-center gap-y-2">
                        <Link
                            to="/"
                            className={`flex flex-col items-center gap-1 md:gap-2 hover:scale-95 duration-100 text-primaryBlack `}
                        >
                            <Falak className="w-12 h-12 bg-transparent rounded-full " />
                            <h1 className=" font-bold text-xl " >{t("falak")}</h1>
                        </Link>
                        <div>
                            <h2 className='text-base font-semibold text-center pb-3 drop-shadow text-primaryBlack'>{t("firstArSearchEngine")}</h2>
                        </div>
                    </div>
                    <div className=" w-full flex flex-col gap-y-4">
                        <PrimaryButton
                            authPopUp={false}
                            roundeOrStyling=' rounded-full '
                            title='signIn'
                            isOpenSideBar={true}
                            loading={false}
                            handleClick={() => {
                                navigate("/sign-in");

                            }}
                            icon={<LoginIcon className="text-primaryWhite" />}
                        />

                        <SecondaryButton
                            roundeOrStyling=' rounded-full '
                            title='googleSignIn'
                            handleClick={handleGoogleAuthClick}
                            loading={false}
                            isOpenSideBar={true}
                            icon={<GoogleIcon className="text-primaryBlack" />}
                        />
                    </div>
                </div>
                <div className="md:hidden w-full flex items-center justify-between py-4">
                    <a className='hover:underline text-primaryBlack ' href='/#'>{t("privacyPolicy")}</a>
                    <a className='hover:underline text-primaryBlack ' href='/#'>{t("serviceTerms")}</a>
                </div>
            </div>

        </div>

    );
};

export default LoginToFalakModal;