import React from 'react';
import bg from "../../assets/bg/standGuy.jpeg";
import PrimaryButton from '../buttons/PrimaryButton';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as LoginIcon } from '../../assets/buttons/loginIcon.svg';
import { ReactComponent as DualArrowIcon } from '../../assets/buttons/dualArrow.svg';
import SecondaryButton from '../buttons/SecondaryButton';
import { ReactComponent as Falak } from "../../assets/identity/falak.svg";
import { ReactComponent as GoogleIcon } from '../../assets/icons/googleIcon.svg';
import { UserAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';


type Props = {
    close: () => void;
};

const GoogleAuthModal: React.FC<Props> = (props: Props) => {

    const { googleSignIn } = UserAuth();

    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleGoogleAuthClick = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log(error);
        }

        props.close();
    };

    return (
        <div className="fixed inset-0 z-[50] flex items-center justify-center">
            <div className='w-[100dvh] h-[100dvh] md:h-fit md:w-[600px] animate-slidedown  rounded-xl relative md:text-lg shadow-xl bg-lightestGreen z-[1] flex flex-col items-center justify-center'>



                <div
                    className='relative shadow-md md:shadow-none md:rounded-xl md:bg-lightGray w-full h-screen md:h-fit md:overflow-hidden '
                >
                    <img
                        className='object-cover w-full h-full '
                        src={bg}
                        alt="falak background"
                    />
                    <div className="py-6 px-3 md:p-6 absolute h-full w-full top-0 start-0 flex flex-col gap-y-2 items-start text-white md:text-primaryBlack p-4 bg-gradient-to-t from-black to-black/5 ">
                        <div className="h-full w-full flex flex-col md:gap-y-8 items-center justify-end gap-y-8">
                            {/* <div className="hidden md:block">
                                <CloseButton close={props.close} />
                            </div> */}
                            <button
                                className=' top-4 start-4 absolute text-white flex items-center gap-x-2 '
                                onClick={() => { navigate('/'); props.close() }}
                            >
                                <DualArrowIcon />
                                <span className='text-base text-white'>
                                    {t("ignore")}
                                </span>
                            </button>

                            <div className="flex flex-col h-full items-center justify-center gap-y-[20%] pt-[20%]">
                                <div className="flex flex-col items-center justify-center gap-y-2">
                                    <Link
                                        to="/"
                                        className={`flex flex-col items-center gap-1 md:gap-2 hover:scale-95 duration-100 text-primaryWhite `}
                                    >
                                        <Falak className="w-12 h-12 md:w-16 md:h-16 bg-transparent rounded-full " />
                                        <h1 className=" font-bold text-3xl " >{t("falak")}</h1>
                                    </Link>
                                    <div>
                                        <h2 className='text-base md:text-lg font-semibold text-center pb-3 drop-shadow text-primaryWhite'>{t("firstArSearchEngine")}</h2>
                                    </div>
                                </div>
                                <div className=" w-full flex flex-col gap-y-4">
                                    <PrimaryButton
                                        authPopUp={false}
                                        roundeOrStyling=' rounded-full '
                                        title='signIn'
                                        isOpenSideBar={true}
                                        loading={false}
                                        handleClick={() => {
                                            navigate("/sign-in");
                                            props.close();

                                        }}
                                        icon={<LoginIcon className="text-white" />}
                                    />

                                    <SecondaryButton
                                        roundeOrStyling=' rounded-full '
                                        title='googleSignIn'
                                        handleClick={handleGoogleAuthClick}
                                        loading={false}
                                        isOpenSideBar={true}
                                        icon={<GoogleIcon className="text-white" />}
                                    />
                                </div>
                            </div>
                            <div className="md:hidden w-full flex items-center justify-between py-4">
                                <Link
                                    to='/privacy-policy'
                                    className='hover:underline text-white '
                                    onClick={() => props.close()}
                                >{t("privacyPolicy")}</Link>
                                <Link
                                    to='/terms-of-service'
                                    className='hover:underline text-white '
                                    onClick={() => props.close()}
                                >{t("serviceTerms")}</Link>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div onClick={props.close} className={` bg-black/20 fixed inset-0 duration-300`}></div>
        </div>
    );
};

export default GoogleAuthModal;