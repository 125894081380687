import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BackArrow } from "../../assets/buttons/arrow.svg";
import { ReactComponent as Falak } from "../../assets/identity/falak.svg";
import { useNavigate } from 'react-router-dom';
import NewsArticleCard from './NewsArticleCard';
import { useFirestoreGetArticles } from '../../context/firestoreDataContext';
import { Helmet } from 'react-helmet';

export const ARTICLES_PER_PAGE = 3;

const Discover: React.FC = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { currentPageArticles, setArticles, currentPage, setCurrentPage, isLoading } = useFirestoreGetArticles();

    const popDeletedArticle = (articleId: string) => {
        setArticles((prev: any) =>
            prev.filter(
                (article: any) => article.id !== articleId
            )
        );
    };

    const nextPage = () => {
        setCurrentPage((prev: number) => prev + 1);
    };

    const prevPage = () => {
        setCurrentPage((prev: number) => Math.max(prev - 1, 1));
    };

    useEffect(() => {
        setCurrentPage(1);
        // eslint-disable-next-line
    }, [navigate]);


    return (
        <>
            <Helmet>
                <title>{currentPageArticles[0]?.title + ", " + currentPageArticles[1]?.title + ", " + currentPageArticles[2]?.title + "|" + t("discoverWithFalak")}</title>
                {(currentPageArticles && currentPageArticles.length > 0) &&
                    <meta
                        name="description"
                        content={`${currentPageArticles[0]?.title + ", " + currentPageArticles[1]?.title + ", " + currentPageArticles[2]?.title}`}
                    />
                }
                <meta
                    name="keywords"
                    content={`${t("discoverWithFalak") + currentPageArticles[0]?.response?.slice(0, 200) + ", " + currentPageArticles[1]?.response?.slice(0, 200) + ", " + currentPageArticles[2]?.response?.slice(0, 200)}`}
                />
                <link rel="canonical" href={"/discover"} />

                <meta property="og:type" content="website" /><meta property="og:url" content={window.location.pathname + window.location.search} />
            </Helmet>

            <div className='pb-4 relative flex flex-col md:justify-start h-full md:min-h-[calc(100vh-100px)] animate-slowfade p-4 bg-white md:bg-lightGray'>

                <div className="max-w-[1440px] md:mx-auto w-full flex items-center justify-between pb-8 md:pb-10">
                    <h1 className='text-xl md:text-3xl font-bold text-primaryBlack'>
                        {t("discoverWithFalak")}
                    </h1>
                    <button
                        className='block shadow-lg p-2 md:p-3 rounded-xl bg-white hover:shadow-sm duration-150'
                        onClick={() => navigate("/")}
                    >
                        <BackArrow className='w-5 h-5' />
                    </button>
                </div>

                {
                    isLoading ?
                        <div className="fixed h-full w-full flex items-center justify-center bg-lightGray">
                            <Falak className="w-16 md:w-16 h-20 md:h-20 animate-spin rounded-full" />
                        </div>
                        :
                        <div className='block space-y-6 '>
                            {
                                currentPageArticles && currentPageArticles.length > 0 ?
                                    <ul className='max-w-[1440px] md:mx-auto space-y-4 md:space-y-6'>
                                        {currentPageArticles
                                            .map((article: any, i: number) => (
                                                <div key={i}>
                                                    <NewsArticleCard index={i} articleInfos={article} onDeleted={popDeletedArticle} />
                                                </div>
                                            ))
                                        }
                                    </ul>
                                    :
                                    <div className="text-center text-xs md:text-sm">{t('emptyResponseArticles')}</div>
                            }
                            <div className='flex items-center justify-center gap-x-4'
                                style={{ direction: "ltr" }}
                            >
                                <button
                                    className=' group md:hover:shadow-md shadow-lg bg-primaryOrange px-3 py-1 rounded-lg text-white font-bold text-lg  disabled:brightness-50 disabled:opacity-15 '
                                    onClick={prevPage}
                                    disabled={currentPage === 1}
                                >
                                    <span className='md:group-hover:translate-x-1 w-full '>
                                        {"<"}
                                    </span>
                                </button>
                                <button
                                    className=' group md:hover:shadow-md shadow-lg bg-primaryOrange px-3 py-1 rounded-lg text-white font-bold text-lg disabled:brightness-50 disabled:opacity-15 '
                                    onClick={nextPage}
                                    disabled={currentPageArticles.length < ARTICLES_PER_PAGE}
                                >
                                    <span className='md:group-hover:-translate-x-1 w-full '>
                                        {">"}
                                    </span>
                                </button>
                            </div>
                        </div>
                }

            </div>
        </>
    );
};

export default Discover;
