import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { sideBarElements } from '../../data/types/sideBarElements';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Falak } from '../../assets/identity/falak.svg';
import { ReactComponent as LoginIcon } from '../../assets/buttons/loginIcon.svg';
import { ReactComponent as HamburgerIcon } from '../../assets/buttons/hamburegerIcon.svg';
import PrimaryButton from '../buttons/PrimaryButton';
import UserInfosSection from '../includes/UserInfosSection';
import FalakPack from '../includes/FalakPack';
import { UserAuth } from '../../context/AuthContext';

const Sidebar: React.FC = () => {

    const location = useLocation();
    const [isOppen, setIsOppen] = useState<boolean>(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { user } = UserAuth();

    const handleHamburgerClick = () => {
        setIsOppen(prev => !prev);
    };

    return (
        <div className="p-2 h-screen bg-transparent ">
            <aside
                className={` overflow-y-auto hidden md:flex flex-col overflow-hidden gap-y-4 bg-red-400 relative h-full bg-gradient-to-tl from-primaryWhite to-primaryWhite rounded-lg shadow-md ${isOppen ? "w-72" : "w-20  duration-150 "} duration-150  `}
            >

                <button
                    className={` ${isOppen ? " ms-[30px] mt-4 " : " mx-auto mt-4 "} `}
                    onClick={handleHamburgerClick}
                >
                    <HamburgerIcon className='hover:scale-[102%] duration-100 hover:drop-shadow-lg' />
                </button>

                <div className='flex flex-col items-center justify-center md:py-4 '>
                    <Link
                        to="/"
                        className={`flex flex-col items-center gap-1 md:gap-2  hover:scale-95 duration-100 `}
                    >
                        <Falak className='bg-transparent rounded-full ' />
                        <span className={`font-bold text-xl ${isOppen ? "scale-100" : " scale-0 hidden "} duration-100 `}>{t("falak")}</span>
                    </Link>
                </div>

                <nav className='w-full h-full flex flex-col py-2'>
                    <ul className='flex flex-col space-y-3 px-4 '>
                        {sideBarElements.map((link) => (
                            <li key={link.id}>
                                <ul className='space-y-3'>
                                    {link.listItems.map((listItem) => (
                                        <li key={listItem.id}>
                                            {
                                                (listItem.id === 3 || listItem.id === 4)
                                                    ?
                                                    <>
                                                        <>
                                                            {// all authenticated users route
                                                                (user && listItem.id === 3) &&

                                                                <Link
                                                                    to={listItem.path}
                                                                    className={`flex w-full gap-x-3 group duration-100 hover:bg-lightOrange p-2 rounded-2xl ${isOppen ? "" : " justify-around"}${location.pathname === listItem.path ? " bg-lightOrange " : ""}`}
                                                                >
                                                                    <listItem.icon
                                                                        className={` ${location.pathname === listItem.path ? " text-primaryOrange " : " text-primaryBlack "} w-6 h-6 `}
                                                                    />
                                                                    <span className={`${isOppen ? " block scale-100 duration-200 " : " hidden scale-0 duration-75 "} ${location.pathname === listItem.path ? " duration-100 whitespace-nowrap text-primaryOrange" : " text-primaryBlack duration-100 "} font-normal whitespace-nowrap group-hover:translate-x-1 duration-100`}>
                                                                        {t(listItem.title)}
                                                                    </span>
                                                                </Link>
                                                            }
                                                        </>

                                                        <>
                                                            {// Only Admin users route
                                                                (listItem.id === 4 && (user && user.isAdmin === true)) &&
                                                                <>
                                                                    <Link
                                                                        to={listItem.path}
                                                                        className={`flex w-full gap-x-3 group duration-100 hover:bg-lightOrange p-2 rounded-2xl ${isOppen ? "" : " justify-around"}${location.pathname === listItem.path ? " bg-lightOrange " : ""}`}
                                                                    >
                                                                        <listItem.icon
                                                                            className={` ${location.pathname === listItem.path ? " text-primaryOrange " : " text-primaryBlack "} w-6 h-6 `}
                                                                        />
                                                                        <span className={`${isOppen ? " block scale-100 duration-200 " : " hidden scale-0 duration-75 "} ${location.pathname === listItem.path ? " duration-100 whitespace-nowrap text-primaryOrange" : " text-primaryBlack duration-100 "} font-normal whitespace-nowrap group-hover:translate-x-1 duration-100`}>
                                                                            {t(listItem.title)}
                                                                        </span>
                                                                    </Link>
                                                                </>
                                                            }
                                                        </>
                                                    </>
                                                    :

                                                    // the rest of public routes
                                                    <>
                                                        {
                                                            (listItem.id !== 4 && listItem.id !== 3) &&
                                                            <Link
                                                                to={listItem.path}
                                                                className={`flex w-full gap-x-3 group duration-100 hover:bg-lightOrange p-2 rounded-2xl ${isOppen ? "" : " justify-around"}${location.pathname === listItem.path ? " bg-lightOrange " : ""}`}
                                                            >
                                                                <listItem.icon
                                                                    className={` ${location.pathname === listItem.path ? " text-primaryOrange " : " text-primaryBlack "} w-6 h-6 `}
                                                                />
                                                                <span className={`${isOppen ? " block scale-100 duration-200 " : " hidden scale-0 duration-75 "} ${location.pathname === listItem.path ? " duration-100 whitespace-nowrap text-primaryOrange" : " text-primaryBlack duration-100 "} font-normal whitespace-nowrap group-hover:translate-x-1 duration-100`}>
                                                                    {t(listItem.title)}
                                                                </span>
                                                            </Link>
                                                        }

                                                    </>
                                            }
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}

                    </ul>

                    <section
                        className='my-auto px-4 py-3'
                    >
                        {
                            user ?
                                <UserInfosSection
                                    isOpenSideBar={isOppen}
                                    userInfos={user}
                                />
                                :
                                <PrimaryButton
                                    authPopUp={true}
                                    roundeOrStyling=' rounded-full '
                                    title='signIn'
                                    isOpenSideBar={isOppen}
                                    loading={false}
                                    handleClick={() => navigate("/sign-in")}
                                    icon={<LoginIcon className="text-white" />}
                                />
                        }

                    </section>

                    <section
                        className={`${isOppen ? " scale-100 duration-200 px-4 " : " h-0 scale-0 duration-100 "}`}
                    >
                        <FalakPack />
                    </section>

                    <section className=' my-auto px-4 py-3'>
                        <div className={`${isOppen ? " scale-100 duration-200 " : " scale-0 duration-100 "} flex flex-col gap-y-1 text-sm drop-shadow-md text-primaryBlack `}>
                            <Link
                                to={"/about-falak"}
                                className='pb-1 text-base '>
                                <span className=' hover:text-primaryOrange duration-150'>
                                    {t("aboutFalak")}
                                </span>
                            </Link>
                            <Link className='hover:underline' to='/privacy-policy'>{t("privacyPolicy")}</Link>
                            <Link className='hover:underline' to='/terms-of-service'>{t("serviceTerms")}</Link>
                            <Link className='hover:underline' to='/about-us'>{"About us"}</Link>
                        </div>
                    </section>

                </nav>
            </aside>
        </div>
    );
};

export default Sidebar;
