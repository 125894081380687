import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCKtoEnad7Awcc20CpRxixW0WS7HfGRX7U",
    authDomain: "askfalak-a495d.firebaseapp.com",
    projectId: "askfalak-a495d",
    storageBucket: "askfalak-a495d.appspot.com",
    messagingSenderId: "754708052999",
    appId: "1:754708052999:web:df5a35b6fb5a0741687ea2",
    measurementId: "G-N42V5K9KGD"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

// eslint-disable-next-line
const analytics = getAnalytics(app);