import { ReactComponent as homeIcon } from "../../assets/icons/sideBar/homeIcon.svg";
import { ReactComponent as netWorkSearchIcon } from "../../assets/icons/sideBar/networkSearchIcon.svg";
import { ReactComponent as historyIcon } from "../../assets/icons/sideBar/historyIcon.svg";
import { ReactComponent as EditArticleIcon } from "../../assets/icons/sideBar/editArticleIcon.svg";

export const sideBarElements = [
    {
        id: 1,
        listItems: [
            {
                id: 1,
                title: "home",
                path: "/",
                icon: homeIcon,
            },
            {
                id: 2,
                title: "discoverWithFalak",
                path: "/discover",
                icon: netWorkSearchIcon,
            },
            {
                id: 3,
                title: "recentQuests",
                path: "/history",
                icon: historyIcon,
            },
            {
                id: 4,
                title: "createArticle",
                path: "/admin/discover",
                icon: EditArticleIcon,
            },

        ],
    }
];
