import { createBrowserRouter } from "react-router-dom";
import Layout from "../layout/Layout";
import NotFound from "../pages/404/NotFound";
import { lazy, Suspense, ReactElement } from "react";
import { ReactComponent as Falak } from "../assets/identity/falak.svg";
import HistoryDetails from "../pages/history/HistoryDetails";
const DiscoverDetails = lazy(() => import("../pages/discover/DiscoverDetails"));
const CreateDiscover = lazy(() => import("../pages/createDiscover/CreateDiscover"));
const AboutFalak = lazy(() => import("../pages/about/AboutFalak"));
const PrivacyPolicy = lazy(() => import("../pages/about/PrivacyPolicy"));
const TermsOfService = lazy(() => import("../pages/about/TermsOfService"));
const VerifyYourEmail = lazy(() => import("../pages/auth/VerifyYourEmail"));
const AboutUs = lazy(() => import("../pages/about/AboutUs"));
const SignIn = lazy(() => import("../pages/auth/SignIn"));
const SignUp = lazy(() => import("../pages/auth/SignUp"));
const Home = lazy(() => import("../pages/home/Home"));
const Discover = lazy(() => import("../pages/discover/Discover"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));
const History = lazy(() => import("../pages/history/History"));
const Settings = lazy(() => import("../pages/settings/Settings"));
const Search = lazy(() => import("../pages/search/Search"));
const RouteProtector = lazy(() => import("./RouteProtector"));


type RouteConfig = {
    path: string;
    element: ReactElement;
    children?: RouteConfig[];
};


const AppSuspense = ({ children }: { children: ReactElement }) => (
    <Suspense
        fallback={
            <div className="fixed h-screen w-screen flex flex-col items-center justify-center bg-lightGray">
                <Falak className="w-16 md:w-16 h-20 md:h-20 animate-spin rounded-full" />
            </div>
        }
    >
        {children}
    </Suspense>
);


const routes: RouteConfig[] = [
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "/",
                element: (
                    <AppSuspense>
                        <Home />
                    </AppSuspense>
                )
            },
            {
                path: "/search",
                element: (
                    <AppSuspense>
                        <Search />
                    </AppSuspense>
                )
            },
            {
                path: "/admin/discover",
                element: (
                    <AppSuspense>
                        <CreateDiscover />
                    </AppSuspense>
                )
            },
            {
                path: "/discover",
                element: (
                    <AppSuspense>
                        <Discover />
                    </AppSuspense>
                )
            },
            {
                path: "/history",
                element: (
                    <AppSuspense>
                        <RouteProtector protectedChild={<History />} />
                    </AppSuspense>
                )
            },
            {
                path: "/history/:id",
                element: (
                    <AppSuspense>
                        <RouteProtector protectedChild={<HistoryDetails />} />
                    </AppSuspense>
                )
            },
            {
                path: "/discover/:id",
                element: (
                    <AppSuspense>
                        <DiscoverDetails />
                    </AppSuspense>
                )
            },
            {
                path: "/settings",
                element: (
                    <AppSuspense>
                        <RouteProtector protectedChild={<Settings />} />
                    </AppSuspense>
                )
            },
            {
                path: "/about-falak",
                element: (
                    <AppSuspense>
                        <AboutFalak />
                    </AppSuspense>
                )
            },
            {
                path: "/privacy-policy",
                element: (
                    <AppSuspense>
                        <PrivacyPolicy />
                    </AppSuspense>
                )
            },
            {
                path: "/terms-of-service",
                element: (
                    <AppSuspense>
                        <TermsOfService />
                    </AppSuspense>
                )
            },
            {
                path: "/about-us",
                element: (
                    <AppSuspense>
                        <AboutUs />
                    </AppSuspense>
                )
            },
        ]
    },
    {
        path: "/sign-in",
        element: (
            <AppSuspense>
                <SignIn />
            </AppSuspense>
        ),
    },
    {
        path: "/sign-up",
        element: (
            <AppSuspense>
                <SignUp />
            </AppSuspense>
        )
    },
    {
        path: "/forgot-password",
        element: (
            <AppSuspense>
                <ResetPassword />
            </AppSuspense>
        )
    },
    {
        path: "/password-reset-email",
        element: (
            <AppSuspense>
                <VerifyYourEmail />
            </AppSuspense>
        )
    },
    {
        path: "/password-reset-verification-sent",
        element: (
            <AppSuspense>
                <VerifyYourEmail />
            </AppSuspense>
        )
    },
    {
        path: "*",
        element: (
            <AppSuspense>
                <NotFound />
            </AppSuspense>
        )
    }
];

export const Router = createBrowserRouter(routes);
