import React from 'react';
import { useTranslation } from 'react-i18next';
import { socialMediaLinks } from '../../constants/socialMediaLinks';


const Footer: React.FC = () => {

    const { t } = useTranslation();

    return (
        <footer className='hidden md:flex px-6 py-3 bg-transparent'>
            <div className="w-full flex items-center justify-between">

                {/* Links */}
                <ul
                    className='flex items-center gap-x-3'
                    style={{ direction: 'ltr' }}
                >
                    {socialMediaLinks.navElements.map((item) => (
                        <li key={item.id}>
                            <a href={item.link} target="_blank" rel="noopener noreferrer">
                                <item.icon className='drop-shadow w-6 h-6 ' />
                            </a>
                        </li>
                    ))}
                </ul>

                {/* Links */}
                <span>
                    <p className='text-sm'>
                        {t("allRights")}
                        <span className=' font-bold text-base '> &copy; </span>
                        {t("falak") + ' ' + new Date().getFullYear()}
                    </p>
                </span>

            </div>
        </footer>
    );
};

export default Footer;