import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as BackArrow } from "../../assets/buttons/arrow.svg";
import { ReactComponent as ShareAnswerIcon } from "../../assets/buttons/shareAnswerIcon.svg";
import { ReactComponent as ThumbUpIcon } from "../../assets/buttons/thumbUp.svg";
import { ReactComponent as ThumbDownIcon } from "../../assets/buttons/thumbDown.svg";
import { ReactComponent as CopyIcon } from "../../assets/buttons/copyIcon.svg";
import { ReactComponent as FalakIcon } from "../../assets/identity/falak.svg";
import ReactPlayer from 'react-player';
import { RWebShare } from 'react-web-share';
import SearchComponent from '../../components/search/SearchComponent';
import Sources from '../search/Sources';
// import PrimaryButton from '../../components/buttons/PrimaryButton';
import secureLocalStorage from 'react-secure-storage';
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../config/firebase/firebaseConfig';
import { ReactComponent as Falak } from "../../assets/identity/falak.svg";
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';

const HistoryDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [result, setResult] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);

    const resultRef = useRef<HTMLDivElement>(null);

    const [like, setLike] = useState(false);
    const [disLike, setDisLike] = useState(false);
    const [isLoadingLike, setIsLoadingLike] = useState(false);
    const [isLoadingDisLike, setIsLoadingDisLike] = useState(false);

    const userId = secureLocalStorage.getItem("cmE5bVRhM3JpZm8=");


    useEffect(() => {

        const fetchQuestionData = async () => {
            try {

                const userId = secureLocalStorage.getItem("cmE5bVRhM3JpZm8=");
                const docRef = doc(db, `/search-history/${userId}/questions/${id}`);

                const docSnapshot = await getDoc(docRef);
                if (docSnapshot.exists()) {
                    const data = docSnapshot.data();
                    setResult(data);
                    setIsLoading(false);
                } else {
                    toast.error(<div className=' text-center text-sm md:text-base text-red-500'>{t('notFound')}</div>, { duration: 3500 })
                    setIsLoading(false);
                    return navigate("/");
                }
            } catch (error: any) {
                toast.error(<div className=' text-center text-xs md:text-sm text-red-500'>{t(error.code)}</div>, { duration: 3500 })
                setIsLoading(false);
            }
        };

        fetchQuestionData();
    }, [id, navigate, t]);


    useEffect(() => {
        if (result) {
            setLike(result.like || false);
            setDisLike(result.dislike || false);
        }
    }, [result]);

    if (isLoading) {
        return (
            <div className="fixed h-screen w-screen flex flex-col items-center justify-center bg-lightGray">
                <Falak className="w-16 md:w-16 h-20 md:h-20 animate-spin rounded-full" />
            </div>
        );
    }

    if (!result) {
        return <div>No data found for this question.</div>;
    }



    const updateLike = async () => {
        if (!like) {
            setIsLoadingLike(true);
            try {

                const questionsCollection = collection(db, `/search-history/${userId}/questions`);

                const q = query(
                    questionsCollection,
                    where('question', '==', result.question)
                );

                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {

                    const documentSnapshot = querySnapshot.docs[querySnapshot.docs.length - 1];


                    await updateDoc(doc(db, `/search-history/${userId}/questions/${documentSnapshot.id}`), {
                        like: true,
                        dislike: false,
                    })
                        .then(() => {
                            setIsLoadingLike(false);
                            setLike(true);
                            setDisLike(false);
                        });
                    toast(<ThumbUpIcon className='text-primaryGreen' />, { duration: 3500 });
                } else {
                    setIsLoadingLike(false);
                    toast.error(<div className=' text-center text-xs md:text-sm text-red-500'>{t("notFound")}</div>, { duration: 3500 })
                }
            } catch (error: any) {
                setIsLoadingLike(false);
                toast.error(<div className=' text-center text-xs md:text-sm text-red-500'>{t(error.code)}</div>, { duration: 3500 })
            }
        }
    };


    const updateDisLike = async () => {
        if (!disLike) {
            setIsLoadingDisLike(true);
            try {
                const questionsCollection = collection(db, `/search-history/${userId}/questions`);

                const q = query(
                    questionsCollection,
                    where('question', '==', result.question)
                );

                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {

                    const documentSnapshot = querySnapshot.docs[querySnapshot.docs.length - 1];

                    await updateDoc(doc(db, `/search-history/${userId}/questions/${documentSnapshot.id}`), {
                        like: false,
                        dislike: true,
                    })
                        .then(() => {
                            setIsLoadingDisLike(false);
                            setLike(false);
                            setDisLike(true);
                        });

                    toast(<ThumbDownIcon className='text-primaryRed' />, { duration: 3500 });
                } else {
                    setIsLoadingDisLike(false);
                    toast.error(<div className=' text-center text-xs md:text-sm text-red-500'>{t("notFound")}</div>, { duration: 3500 })
                }
            } catch (error: any) {
                setIsLoadingDisLike(false);
                toast.error(<div className=' text-center text-xs md:text-sm text-red-500'>{t(error.code)}</div>, { duration: 3500 })
            }
        }
    };


    const handleCopy = () => {
        if (resultRef.current) {
            const range = document.createRange();
            range.selectNode(resultRef.current);
            window.getSelection()?.removeAllRanges();
            window.getSelection()?.addRange(range);
            document.execCommand('copy');
            window.getSelection()?.removeAllRanges();
            toast.success(<div className='text-primaryBlack text-sm md:text-base'>{t("answerCopied")}</div>, { duration: 3500 });
        }
    };


    return (
        <>
            <Helmet>
                <title>{t("question") + " | " + result.question}</title>
                <meta
                    name="description"
                    content={`${t("falak") + " |  فلك  Ai - للمحتوى العربي  " + t("firstArSearchEngine")}`}
                />
                <meta
                    name="keywords"
                    content={result.question || `${t("falak")}`}
                />
                <link rel="canonical" href={`${"/search?query=" + result.question}`} />

                <meta property="og:type" content="website" /><meta property="og:url" content={window.location.pathname + window.location.search} />
            </Helmet>

            <main className=" relative flex flex-col gap-y-4 md:min-h-[calc(100vh-100px)] animate-slowfade  p-4 bg-white md:bg-lightGray     ">

                <div className="md:hidden w-full flex items-center justify-between flex-row-reverse ">

                    <button
                        className='block shadow-lg p-2 md:p-3 rounded-xl bg-white hover:shadow-sm duration-150'
                        onClick={() => navigate("/")}
                    >
                        <BackArrow className='w-5 h-5' />
                    </button>

                    {/* <div className="block">
                        <PrimaryButton
                            authPopUp={false}
                            roundeOrStyling='rounded-full text-start '
                            title='downloadApp'
                            handleClick={ () => console.log('')}
                            loading={false}
                            isOpenSideBar={true}
                        />
                    </div> */}
                </div>

                <div className="w-full md:flex items-center justify-between">

                    <h1 className='text-lg md:text-2xl font-bold drop-shadow text-primaryBlack'>
                        {t("question") + " : " + result.question}
                    </h1>

                    {/* <div className="hidden md:block">
                        <PrimaryButton
                            authPopUp={false}
                            roundeOrStyling='rounded-full text-start '
                            title='downloadApp'
                            handleClick={ () => console.log('')}
                            loading={false}
                            isOpenSideBar={true}
                        />
                    </div> */}

                </div>


                <div className=" md:pb-20 w-full flex flex-col md:flex-row istar gap-4 md:gap-6">

                    <div className='md:w-3/4 px-2 space-y-6 md:space-y-8'>

                        <div className="min-h-[128px] md:min-h-[115px] ">
                            <Sources isLoading={isLoading} result={result} />
                        </div>

                        {/* Answer */}
                        <div className="flex items-center justify-between">
                            <div className='flex items-start gap-x-2'>
                                <FalakIcon className={`w-6 md:w-7 h-6 md:h-7 drop-shadow-lg bg-transparent rounded-full ${isLoading && " animate-spin "} `} />
                                <h5 className='font-bold text-base md:text-lg'>{t('answer')}</h5>
                            </div>
                            <div className="flex items-center gap-x-4 md:gap-x-6 ">
                                <span className='cursor-pointer hover:drop-shadow-lg hover:scale-[105%] hover:-rotate-6 duration-150'>
                                    <RWebShare
                                        data={{
                                            text: "شارك الإجابة ",
                                            url: window.location.href,
                                            title: "فلك",
                                        }}
                                    >
                                        <ShareAnswerIcon />
                                    </RWebShare>
                                </span>

                                <button
                                    className='cursor-pointer md:hover:drop-shadow-lg md:hover:scale-[105%] md:hover:-rotate-6 duration-150'
                                    onClick={handleCopy}
                                >
                                    <CopyIcon className='w-5' />
                                </button>
                                {result && <button
                                    className='cursor-pointer md:hover:scale-[105%] md:hover:-rotate-6 duration-150'
                                >
                                    <ThumbDownIcon className={`'w-5' ${disLike ? "text-primaryRed scale-[105%] drop-shadow-lg " : "text-primaryBlack"} ${isLoadingDisLike && "text-gray-500 animate-pulse"}`} onClick={updateDisLike} />
                                </button>}
                                {result && <button
                                    className='cursor-pointer md:hover:scale-[105%] md:hover:-rotate-6 duration-150'
                                >
                                    <ThumbUpIcon className={`'w-5' ${like ? "text-primaryGreen scale-[105%] drop-shadow-lg " : "text-primaryBlack"} ${isLoadingLike && "text-gray-500 animate-pulse"}`} onClick={updateLike} />
                                </button>}

                            </div>
                        </div>
                        <div className=" px-2 md:px-6 pb-4">
                            <div ref={resultRef} className='aiResponse ' dangerouslySetInnerHTML={{ __html: result.htmlData }}></div>
                        </div>
                    </div>
                    {/* videos */}
                    <div className=" md:w-1/4 w-full md:block flex items-center gap-x-4 overflow-auto pb-4 mb-16">
                        {!result?.videos?.error && result?.videos?.map((video: any, i: number) => (
                            <div className="mx-auto md:w-fit w-full grid gap-y-2" key={i}>
                                <div className=" rounded-xl">
                                    <div className="w-[85vw] md:w-full h-48 rounded-lg overflow-hidden my-4">

                                        <ReactPlayer
                                            url={video?.url}
                                            width={"100%"}
                                            height={"100%"}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <SearchComponent styling=' w-full hidden md:block fixed z-30 -bottom-[40px] md:bottom-14' />

            </main >
        </>
    );
};

export default HistoryDetails;
