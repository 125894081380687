import React from 'react';
import SliderComponent from './SliderComponent';
import { ReactComponent as EarthNetworkIcon } from '../../assets/icons/earthNetworkIcon.svg';
import { useTranslation } from 'react-i18next';

type Props = {
    result: any;
    isLoading: boolean;
};

const Sources: React.FC<Props> = (props: Props) => {

    const { t } = useTranslation();

    return (
        <div className=' w-full flex flex-col gap-y-2 px-4 md:px-0 '>
            <div className='flex items-start gap-x-2'>
                <EarthNetworkIcon className='w-6 h-6 drop-shadow-lg' />
                <h5 className='font-bold text-base md:text-lg'>{t('sources')}</h5>
            </div>
            <SliderComponent isLoading={props.isLoading} result={props.result} />
        </div>
    );
};

export default Sources;